import styled from "styled-components"

const ServicesGrid = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  padding-bottom: ${props => (props.noBottomStuff ? 0 : "20px")};
  /*margin-bottom: ${props => (props.noBottomStuff ? 0 : "20px")};*/
  /*border-bottom: ${props =>
    props.noBottomStuff ? "none" : "1px solid #d9d9d9"};*/
`

const ServicesCardMain = styled.div`
  width: 100%;
  flex: 1;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  border-bottom: 1px solid ${props => props.theme.orange};
  display: flex;
  align-items: flex-start;

  .service-icon {
    width: 50px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      max-width: 100%;
      max-height: 50px;
    }
  }

  .service-content {
    flex: 1;
  }

  h4 {
    font-size: 14px;
    margin: 0;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    margin: 5px 0 0 0;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`

const ServicesCard = styled.div`
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  &:last-of-type {
    margin-bottom: 0;
  }

  .service-icon {
    width: 32px;
    height: 32px;
    background: #ff7642;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 14px;
    }
  }

  .service-content {
    flex: 1;
  }

  h4 {
    font-size: 16px;
    margin: 0;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin: 5px 0 0 0;
  }
`
export { ServicesGrid, ServicesCardMain, ServicesCard }
