import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Icon from "../utilities/icons"
import { SideInfoBox } from "../components/styles/SideInfoBox"
import styled from "styled-components"
import {
  ServicesGrid,
  ServicesCardMain,
  ServicesCard,
} from "../components/styles/GridStyles"
import SEO from "../components/seo"
import DomesticIcon from "../images/icons/icon_domestic_orange.png"
import CommercialIcon from "../images/icons/icon_commercial_orange.png"
import IndustrialIcon from "../images/icons/icon_industrial_orange.png"

const Services = props => {
  const services = props.data.services.edges
  const intros = props.data.intros.edges[0].node.childMarkdownRemark.frontmatter

  const domestic_services = services.filter(
    item => item.node.frontmatter.domestic
  )
  console.log("domestic services = ")
  console.log(domestic_services)

  const commercial_services = services.filter(
    item => item.node.frontmatter.commercial_industrial
  )
  console.log("commercial services = ")
  console.log(commercial_services)

  return (
    <Layout>
      <SEO
        title="Electrical Services |  MJ Electrical | Electrician in Newark"
        description="Electrical Services offered by MJ Electrical - Electrician in Newark on Trent and surrounding areas. Covering Nottinghamshire, Lincolnshire, Leicestershire, Derbyshire"
      />
      <div className="standard-page-container">
        <div className="inner-container">
          <h1>Services</h1>

          <div className="two-column-layout-wrapper">
            <div className="two-column-layout-item_wide ">
              {/*<p>{intros.services_body_content}</p>*/}

              <h4>Domestic Services</h4>
              <ServicesGrid>
                <ServicesCardMain>
                  <div className="service-icon">
                    <img src={DomesticIcon} alt="Domestic Icon" />
                  </div>
                  <div className="service-content">
                    <h4>Domestic</h4>
                    <p>{intros.domestic_content}</p>
                  </div>
                </ServicesCardMain>
              </ServicesGrid>

              <ServicesGrid
                style={{
                  borderBottom: "1px solid #d9d9d9",
                  marginBottom: "20px",
                  paddingBottom: "20px",
                }}
              >
                {domestic_services.map((service, i) => (
                  <ServicesCard>
                    <div className="service-icon">
                      <Icon name="check" height="24" color="#ffffff" />
                    </div>
                    {console.log(service)}
                    <div className="service-content">
                      <h4>{service.node.frontmatter.title}</h4>
                      {service.node.frontmatter.service_description ? (
                        <p>{service.node.frontmatter.service_description}</p>
                      ) : (
                          ""
                        )}
                    </div>
                  </ServicesCard>
                ))}
              </ServicesGrid>

              <h4>Commercial &amp; Industrial Services</h4>
              <ServicesGrid>
                <ServicesCardMain>
                  <div className="service-icon">
                    <img src={CommercialIcon} alt="Commercial Icon" />
                  </div>
                  <div className="service-content">
                    <h4>Commercial &amp; Industrial</h4>
                    <p>{intros.commercial_content}</p>
                  </div>
                </ServicesCardMain>
              </ServicesGrid>

              <ServicesGrid noBottomStuff="true">
                {commercial_services.map((service, i) => (
                  <ServicesCard>
                    <div className="service-icon">
                      <Icon name="check" height="24" color="#ffffff" />
                    </div>
                    {console.log(service)}
                    <div className="service-content">
                      <h4>{service.node.frontmatter.title}</h4>
                      {service.node.frontmatter.service_description ? (
                        <p>{service.node.frontmatter.service_description}</p>
                      ) : (
                          ""
                        )}
                    </div>
                  </ServicesCard>
                ))}
              </ServicesGrid>
            </div>

            <div className="two-column-layout-item_narrow">
              <SideInfoBox className="global-box-shadow">
                <h3 className="sidebox-title">Quick Contact</h3>
                <ul className="contact-box-list">
                  <li>
                    <b>Phone / Text:</b> 07963 168251
                  </li>
                  <li>
                    <b>Email: </b>
                    <a href="mailto:info@mjelectric.co.uk">
                      {" "}
                      info@mjelectric.co.uk
                    </a>
                  </li>
                  {/*<li>
                    <b>Facebook: </b>
                    <a href="">
                      <Icon
                        name="facebook"
                        color="#3C5A99"
                        width="16"
                        height="16"
                      />
                    </a>
                  </li>*/}
                </ul>
              </SideInfoBox>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Services

export const query = graphql`
  query Services {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "data/services/.*.md$/" } }
      sort: { order: ASC, fields: frontmatter___title }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            service_description
            commercial_industrial
            domestic
          }
        }
      }
    }
    intros: allFile(filter: { name: { eq: "services_content" } }) {
      edges {
        node {
          childMarkdownRemark {
            id
            frontmatter {
              services_body_content
              domestic_content
              commercial_content
              industrial_content
            }
          }
        }
      }
    }
  }
`
